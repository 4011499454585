<template>
    <section class="config-obras-calendario-talleres">
        <div class="row">
            <div class="col-10 col-lg-10">
                <!-- title + addCalendar Option  Con modal-->
                <titulo-divisor titulo="Calendarios predeterminados">
                    <el-tooltip content="Crear calendario" effect="light" visible-arrow>
                        <div class="bg-general br-4 wh-34 d-middle-center cr-pointer" @click="createNewCalendarTaller">
                            <i class="icon-calendar-plus text-white f-20" />
                        </div>
                    </el-tooltip>
                </titulo-divisor>
                <section v-for="calendario in calendario_talleres" :key="calendario.id" class="mb-4">
                    <!-- calendars list -->
                    <div class="row">
                        <div class="col-10 my-auto">
                            <div class="row mx-0 bg-general text-white br-4 py-1 justify-content-between">
                                <div class="col-8 my-auto">
                                    <div class="d-middle">
                                        <i class="icon-calendar-month-outline" />
                                        <p class="f-12"> {{ calendario.nombre }} </p>
                                    </div>
                                </div>
                                <div class="col-1 px-1">
                                    <i class="icon-ray-start-arrow f-20" />
                                </div>
                                <div class="col-1 px-1">
                                    <i class="icon-clock-outline f-20" />
                                </div>
                                <div class="col-auto ml-auto">
                                    <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                                        <template #content>
                                            <!-- <p class="p-2 cr-pointer item" @click="editCalendar(calendario)">Editar</p> -->
                                            <p class="p-2 cr-pointer item" @click="editNewCalendarTaller(calendario)">Editar</p>
                                            <p class="p-2 cr-pointer item" @click="deleteCalendar(calendario.id)">Eliminar</p>
                                        </template>
                                        <i class="icon-dots-vertical cr-pointer f-20" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 px-0">
                            <el-tooltip content="Crear actividad" effect="light" placement="right" visible-arrow>
                                <i class="icon-plus-box f-25 text-general cr-pointer" @click="addActivity(calendario.id)" />
                            </el-tooltip>
                        </div>
                    </div>
                    <draggable tag="div" :list="calendario.actividades" class="row" handle=".handle" @change="updatePosition($event, calendario.actividades)">
                        <div v-for="(act,index1) in calendario.actividades" :key="index1" class="col-10">
                            <div class="row mx-0 w-100 border">
                                <div class="col-8 my-auto">
                                    <div class="d-middle">
                                        <i class="icon-menu handle" />
                                        <i class="icon-circle-fill mr-1" :style="`color: ${act.color}`" />
                                        <el-tooltip placement="bottom-start" effect="light" visible-arrow>
                                            <template #content>
                                                <p class="f-12 terxt-5d f-600 mb-1"> {{ act.nombre }} </p>
                                                <p class="f-12 terxt-5d lh-15" style="max-width: 340px"> {{ act.descripcion ? act.descripcion : 'Sin descripción' }} </p>
                                            </template>
                                            <p class="f-12"> {{ act.nombre }} </p>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="col-1 f-12 px-1 my-auto">
                                    Día {{ act.dia_inicio }}
                                </div>
                                <div class="col-1 f-12 px-1 my-auto">
                                    {{ act.duracion_dias }} Días
                                </div>
                                <div class="col-auto ml-auto">
                                    <div class="d-flex icon-option">
                                        <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                                            <template #content>
                                                <p class="p-2 cr-pointer item" @click="editActivity(act, calendario.id)">Editar</p>
                                                <p class="p-2 cr-pointer item" @click="deleteActivity(act.id)">Eliminar</p>
                                            </template>
                                            <i class="icon-dots-vertical cr-pointer f-20" />
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </draggable>
                </section>
            </div>
        </div>
        <!-- partials -->
        <modal-eliminar ref="deleteCalendar" title="Eliminar calendario" mensaje="¿Está seguro que quiere eliminar este calendario?" @delete="handleCalendarDelete"/>
        <modal-eliminar ref="deleteGroup" title="Eliminar grupo de actividades" mensaje="¿Está seguro que quiere eliminar este grupo?" />
        <modal-eliminar ref="deleteActivity" title="Eliminar actividad" mensaje="¿Está seguro que quiere eliminar esta actividad?" @delete="handleActivityDelete" />
        <modal-add-activity ref="addActivity" @addSubactivity="handleAddActivity" @editSubactivity="handleEditActivity"/>
        <modal-edit-activity ref="editActivity" titulo="Crear actividad" />
        <modal-create-calendar-taller ref="createCalendarTaller" />
    </section>
</template>

<script>
import { WorkshopCalendarFactory } from '~/domain/entities/configurar/obras/calendario_talleres/factories/WorkshopCalendarFactory'
import { mapGetters, mapActions } from 'vuex'
import modalAddActivity from './partials/modalAddSubactivity.vue'
import modalEditActivity from './partials/modalEditActivity.vue'
import modalCreateCalendarTaller from './partials/modalCreateCalendarTaller.vue'
export default {
    components:{
        modalAddActivity,
        modalEditActivity,
        modalCreateCalendarTaller,
    },
    data(){
        return{
            workshopCalendar: WorkshopCalendarFactory.instantiateEmpty(),
            calendarToEdit: {},
            activityToEdit: {},
            calendarToDelete: {},
            activityToDelete: {},
            visibleCreateCalendar: false,
            visibleAddNewGroup: false,
            visibleEditGroup: false,
            tituloModalActividad: 'Crear actividad'
        }
    },
    computed: {
        ...mapGetters({
            calendario_talleres: 'configurar/obras/calendario_talleres/calendario_talleres'
        })
    },
    methods:{
        ...mapActions({
            obtenerCalendariosTaller: 'configurar/obras/calendario_talleres/Action_get_calendario_talleres',
            crearCalendariosTaller: 'configurar/obras/calendario_talleres/Action_create_calendario_talleres',
            editarCalendariosTaller: 'configurar/obras/calendario_talleres/Action_edit_calendario_talleres',
            eliminarCalendariosTaller: 'configurar/obras/calendario_talleres/Action_delete_calendario_talleres',
            crearActividadCalendariosTaller: 'configurar/obras/calendario_talleres/Action_create_calendario_talleres_actividad',
            editarActividadCalendariosTaller: 'configurar/obras/calendario_talleres/Action_edit_calendario_talleres_actividad',
            eliminarActividadCalendariosTaller: 'configurar/obras/calendario_talleres/Action_delete_calendario_talleres_actividad',
            updatePositionActivity: 'configurar/obras/calendario_talleres/Action_update_position_calendar_activity',
            Action_get_actividad_predecesora_talleres: 'selects/selects/Action_get_actividad_predecesora_talleres',
            
        }),
        async createNewCalendar(){
            if(this.workshopCalendar.id != '' && this.visibleCreateCalendar != null){
                await this.editarCalendariosTaller({
                    id: this.workshopCalendar.id,
                    payload: this.workshopCalendar
                })
            } else {
                await this.crearCalendariosTaller(this.workshopCalendar)
            }
            this.workshopCalendar = WorkshopCalendarFactory.instantiateEmpty()
            this.visibleCreateCalendar = !this.visibleCreateCalendar
        },
        editCalendar(calendar){
            this.workshopCalendar = calendar
            this.visibleCreateCalendar = true
        },
        deleteCalendar(id){
            this.calendarToDelete = id
            this.$refs.deleteCalendar.toggle()
        },
        addNewGroup(){
            
        },
        editOnGroup(id){
            this.$refs[`editarGrupo-${id}`][0].click()
        },
        deleteGroup(){
            this.$refs.deleteGroup.toggle()
        },
        deleteActivity(id){
            this.activityToDelete = id
            this.$refs.deleteActivity.toggle()
        },
        async addActivity(id_calendario){
            await this.Action_get_actividad_predecesora_talleres({id_calendario})
            this.calendarToEdit = id_calendario
            this.$refs.addActivity.toggle()
        },
        async editActivity(data, id_calendario){
            await this.Action_get_actividad_predecesora_talleres({id_calendario, id_actividad: data.id})
            this.$refs.addActivity.toggle(data)
        },

        toggleVisible(){
            this.visibleCreateCalendar = !this.visibleCreateCalendar
            this.workshopCalendar = WorkshopCalendarFactory.instantiateEmpty()
        },

        async handleAddActivity(actividad){
            await this.crearActividadCalendariosTaller({
                ...actividad,
                id_configuracion_calendarios: undefined,
                id_configuracion_calendarios_talleres: this.calendarToEdit
            })
            //await this.obtenerCalendariosTaller()
            await this.obtenerCalendariosTaller()
        },

        async handleEditActivity(actividad){
            await this.editarActividadCalendariosTaller({
                payload: actividad,
                id: actividad.id
            })
            await this.obtenerCalendariosTaller()
        },

        async handleActivityDelete(){
            await this.eliminarActividadCalendariosTaller({
                id: this.activityToDelete
            })
            await this.obtenerCalendariosTaller()
        },

        async handleCalendarDelete(){
            await this.eliminarCalendariosTaller({
                id: this.calendarToDelete
            })
            await this.obtenerCalendariosTaller()
        },

        createNewCalendarTaller(){
            this.$refs.createCalendarTaller.toggle()
        },
        editNewCalendarTaller(data){
            this.$refs.createCalendarTaller.toggle(data)
        },
        async updatePosition(e, actividades){
            let obj = {
                "id_configuracion_calendarios": e.moved.element.id_configuracion_calendarios_talleres,
                "id_configuracion_calendarios_actividades": actividades.map(el => el.id),
            }
            await this.updatePositionActivity(obj)
            await this.obtenerCalendariosTaller()
        }

    },
    async mounted(){
        await this.obtenerCalendariosTaller()
    }

}
</script>

<style lang="scss" scoped>
.config-obras-calendario-talleres{
    .handle{
        cursor: grabbing;
    }
    .icon-ray-start-arrow, .icon-clock-outline{
        &::before{
            margin-left: 0px !important;
        }
    }
}
</style>