<template>
    <modal ref="modalCreateCalendar" :titulo="` ${workshopCalendar.id ? 'Editar' : 'Crear'} calendario predeterminado`" 
        :adicional="`${workshopCalendar.id ? 'Editar' : 'Crear'}`" 
        @adicional="actionSave">
        <div class="row m-3 f-15 justify-content-center">
           <div class="col-10 my-2">
                <p class="input-label-top">Nombre del calendario</p>
                <ValidationObserver ref="validatorAddCalenddarTaller">
                    <ValidationProvider v-slot="{ errors }" name="nombre calendario" rules="required">
                        <el-input v-model="workshopCalendar.nombre" placeholder="Nombre del calendario" size="small" class="w-100" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </ValidationObserver>
                <!-- <el-input v-model="workshopCalendar.nombre" placeholder="Nombre del calendario" size="small" class="w-100" /> -->
            </div>
        </div>
    </modal>
</template>

<script>
import { WorkshopCalendarFactory } from '~/domain/entities/configurar/obras/calendario_talleres/factories/WorkshopCalendarFactory'
import { mapActions } from 'vuex'
export default {
    data(){
        return {
            workshopCalendar: WorkshopCalendarFactory.instantiateEmpty(),
            nombre: '',
        }
    },
    methods: {
        ...mapActions({
            crearCalendariosTaller: 'configurar/obras/calendario_talleres/Action_create_calendario_talleres',
            editarCalendariosTaller: 'configurar/obras/calendario_talleres/Action_edit_calendario_talleres',
        }),
        toggle(data){
            if (data != undefined) {
                this.workshopCalendar = {...data}
            }
            this.$refs.modalCreateCalendar.toggle()
            this.$refs.validatorAddCalenddarTaller.reset();
        },
        async actionSave(){
            const valid = await this.$refs.validatorAddCalenddarTaller.validate()
            if (valid) {
                if (this.workshopCalendar.id != "") {
                     await this.editarCalendariosTaller({
                        id: this.workshopCalendar.id,
                        payload: this.workshopCalendar
                    })
                    this.cleanFields()
                    this.$refs.modalCreateCalendar.toggle()
                } else {
                    await this.crearCalendariosTaller(this.workshopCalendar)
                    this.cleanFields()
                    this.$refs.modalCreateCalendar.toggle()
                }
            }
        },
        cleanFields(){
            this.workshopCalendar = WorkshopCalendarFactory.instantiateEmpty()
        }
    }
}
</script>