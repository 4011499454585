<template>
    <modal ref="modalEditActivy" titulo="Editar actividad" tamano="modal-lg" adicional="Crear" @adicional="edit">
        <div class="row m-3 f-15">
            <div class="col-8">
                <p class="input-label-top">Nombre</p>
                <el-input v-model="model.activityName" class="w-100" size="small" />
            </div>
            <div class="col-4">
                <p class="input-label-top">Duración (días)</p>
                <el-input v-model="model.durationDays" class="w-100" size="small" />
            </div>
        </div>
        <div class="row m-3 f-15">
            <div class="col-3 my-auto">
                <p class="input-label-top">Día inicio</p>
                <el-input v-model="model.initialDay" class="w-100" size="small" />
            </div>
            <div class="col-4 my-auto pt-3">
                <el-checkbox v-model="model.predecessorActivity">Actividad predecesora</el-checkbox>
            </div>
        </div>
        <div class="row m-3 mb-4 f-15">
            <div class="col-8">
                <p class="input-label-top">Descripción</p>
                <el-input v-model="model.description" type="textarea" :autosize="{ minRows: 4 }" class="w-100" />
            </div>
            <div class="col-4 my-auto">
                <chrome-picker v-model="model.color" ref="colorPicker" class="w-100" />
            </div>
        </div>
    </modal>
</template>

<script>
import { Chrome } from 'vue-color'
export default {
    components:{
        'chrome-picker': Chrome
    },
    data(){
        return {
            model:{
                activityName: '',
                durationDays: '',
                initialDay: '',
                description: '',
                predecessorActivity: false,
                color: '#0b56a7',
            },
        }
    },
    watch:{
        color(){
            this.model.color = this.$refs.colorPicker.val.hex
        }
    },
    methods: {
        toggle(data){
            this.model = {
                activityName: data.name,
                durationDays: data.duration,
                initialDay: data.initialDay,
                description: data.description,
                predecessorActivity: data.predecessorActivity,
                color: data.color,
            }
            this.$refs.modalEditActivy.toggle()
        },
        edit(){
            this.model = {
                activityName: '',
                durationDays: '',
                initialDay: '',
                description: '',
                predecessorActivity: false,
                color: '#0b56a7',
            },
            this.toggle(this.model)
        }
    }
}
</script>